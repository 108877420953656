import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  BannerLinks,
  TextBox,
  BlogSlider,
  Breadcrumbs
} from '../../components'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import BannerImage from '../../assets/projektuebersicht.jpg'

import { generateBreadcrumbsLinks } from '../../utils/index'

const DigitalNeueKunden = () => {
  const links = generateBreadcrumbsLinks('/blog/digital-neue-kunden', 'Digital neue Kunden')
  return (
    <Layout>
      <Helmet
        title="Digital neue Kunden fischen"
        meta={[
          {
            name: 'description',
            content:
              'Ihre Website ist Ihr wichtigstes Tool, um aus möglichen Interessenten Kunden zu machen, bevor Sie überhaupt wissen, dass diese Interesse an Ihren Angeboten haben.'
          },
          {
            name: 'keywords',
            content:
              'Leads holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Leads generieren, call-to-action, call to action, customer journey, landingpage'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Neugierig bleiben
            <br />
            Neues lernen
          </span>
        }
      />
       <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="Digital neue Kunden “fischen”"
        text="Ihre Website ist Ihr wichtigstes Tool, um aus möglichen Interessenten Kunden zu machen, bevor Sie überhaupt wissen, dass diese Interesse an Ihren Angeboten haben."
      />
      <TextBox
        title=""
        text={
          <span>
            Natürlich ist die gesamte Customer Journey im Auge zu behalten
            Leadgenerierung über die Website bzw. über sauber strukturierte
            Landingpages mit klarem Call to Action ist jedoch Ihr Dreh- und
            Angelpunkt für wertvolle Leads in der Anfangsphase. Das heißt in
            dieser frühen Phase des Entscheidungsprozesses gezielt Angebote und
            die richtigen Informationen zu platzieren und auch gefunden zu werden.{' '}
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            Wie schaut Ihre Content-und Leadgenerierungsstrategie derzeit aus? Wie schaffen Sie den Prozess von der Leadgenerierung über verschiedene Kanäle und beteiligte Personen über die Qualifizierung zum Inbound-Marketing bis hin zur 360 Grad Sicht im CRM?{' '}
          </span>
        }
      />
      <TextBox
        title=""
        text={
          <span>
            Nehmen Sie sich die Zeit mit uns 2-3 Hauptzielgruppen auf ihrer Reise durch Ihr Unternehmen und durch die Kaufphasen in einem Workshop zu begleiten. Durch unsere Zertifizierungen können Sie unter bestimmten Voraussetzungen bis zu 50 % Förderungen lukrieren. Fragen Sie uns!{' '}
          </span>
        }
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}
export default DigitalNeueKunden
